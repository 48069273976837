import { Experiment } from "@amplitude/experiment-js-client"
import { navigate } from "gatsby-link"
import { NonBusinessEmailDomains } from "../constants/non-business-email-domains"

export default async function marketo() {
  if (typeof window !== "undefined" && window.document) {
    window._goodhireHubspotForm =
      window._goodhireHubspotForm ||
      (async function () {
        //Form validation
        class MarketoFormController {
          constructor(form, fields) {
            this.form = form
            this.fields = fields
            this.marketoFormObject = null
            this.abTestGroup = null
            this.assistedAbTestGroup = null
          }

          async initialize() {
            // Marketo-supported hack for fixing _mktoReferrer not working updating in SPAs.
            window.MktoForms2.whenReady(function (readyForm) {
              const nativeGetValues = readyForm.getValues

              readyForm.onSubmit(function (submittingForm) {
                submittingForm.getValues = function () {
                  const values = nativeGetValues()
                  Object.defineProperty(values, "_mktoReferrer", {
                    value: document.location.href,
                    enumerable: true,
                  })
                  return values
                }

              })
            })

            window.MktoForms2.loadForm(
              `//${this.form.dataset.munchkin}.mktoweb.com`,
              this.form.dataset.munchkin,
              this.form.dataset.formId,
              marketoFormObject => {
                this.marketoFormObject = marketoFormObject

                this.marketoFormObject.onSuccess(formValues => {

                  this.redirectPostSubmit(formValues)

                  // prevent any default submission behavior
                  return false
                })

                this.populateAnnualVolumneValues()
              }
            )

            this.addPhoneNumberMask()
            this.addErrorSpans()
            this.validateOnEntry()
            this.validateOnSubmit()
            // await this.setUpABTesting()
          }

          async setUpABTesting() {
            if (
              window.location.pathname === '/get-started/signup/' &&
              !!process.env.GATSBY_AMPLITUDE_EXPERIMENT_KEY?.length
            ) {
              // (1) Initialize the experiment client with Amplitude Analytics.
              const experiment = Experiment.initializeWithAmplitudeAnalytics(
                `${process.env.GATSBY_AMPLITUDE_EXPERIMENT_KEY}`
              )

              // (2) Start the SDK and await the promise result.
              await experiment.start()

              // (3) Lookup a flag's variant.
              const variant = experiment.variant(
                `${process.env.GATSBY_AMPLITUDE_EXPERIMENT_FLAG}`,
                {
                  value:
                    process.env.GATSBY_AMPLITUDE_DEFAULT_VARIANT || "control",
                }
              )

              this.abTestGroup = variant.value
            }

          }

          async startAssistedExperiment(formData) {
            const assistedForms = ['1628', '1630', '1632']

            if (assistedForms.includes(this.form.dataset.formId) && window.location.pathname !== '/get-started/signup/') {
              const assistedExperiment = Experiment.initializeWithAmplitudeAnalytics(
                `${process.env.GATSBY_AMPLITUDE_EXPERIMENT_KEY}`
              )

              await assistedExperiment.start()

              const assistedVariant = assistedExperiment.variant(`ab-11-good-better-best-assisted`, {
                value: process.env.GATSBY_AMPLITUDE_DEFAULT_VARIANT || "control",
              })


              this.assistedAbTestGroup = assistedVariant.value

            }

          }

          // Option values aren't appearing on prod???
          // Hardcode the values if they're missing...
          populateAnnualVolumneValues() {
            const field = this.form.querySelector(
              'select[name="Annual_Volume_of_Checks__c"]'
            )
            if (!field) return
            const fieldOptions = [...field.options]
            fieldOptions.forEach(option => {
              switch (option.text) {
                case "I want to run a background check on myself":
                  option.value = 1
                  break
                case "1 – 10":
                  option.value = 5
                  break
                case "11 – 25":
                  option.value = 20
                  break
                case "26 – 50":
                  option.value = 35
                  break
                case "51 – 299":
                  option.value = 150
                  break
                case "300-1000":
                case "300 – 1000":
                  option.value = 750
                  break
                case "1000+":
                  option.value = 1001
                  break
                default:
                  option.value = ""
              }
            })
          }

          // Client wants phone number autoformatted to ___-___-____ (where '_' is a number)
          // Code taken from https://stackoverflow.com/questions/12578507/implement-an-input-with-a-mask
          addPhoneNumberMask() {
            if (this.form.dataset.phone_validation === "1") {
              document.getElementById("Phone").onkeypress = this.validate_int
              document.getElementById("Phone").onkeyup = this.phoneNumberMaskFn
            }
          }
          phoneNumberMaskFn() {
            var myMask = "___-___-____"
            var phoneInput = document.getElementById("Phone")
            var myText = ""
            var myNumbers = []
            var myOutPut = ""
            var theLastPos = 1
            myText = phoneInput.value
            //get numbers
            for (var i = 0; i < myText.length; i++) {
              if (!isNaN(myText.charAt(i)) && myText.charAt(i) != " ") {
                myNumbers.push(myText.charAt(i))
              }
            }
            //write over mask
            for (var j = 0; j < myMask.length; j++) {
              if (myMask.charAt(j) == "_") {
                //replace "_" by a number
                if (myNumbers.length == 0) {
                  myOutPut = myOutPut + myMask.charAt(j)
                } else {
                  myOutPut = myOutPut + myNumbers.shift()
                  theLastPos = j + 1 //set caret position
                }
              } else {
                myOutPut = myOutPut + myMask.charAt(j)
              }
            }
            document.getElementById("Phone").value = myOutPut
            document
              .getElementById("Phone")
              .setSelectionRange(theLastPos, theLastPos)
          }
          /**
           * Validate int for phone #
           * charCode [48,57]     Numbers 0 to 9
           * keyCode 46           "delete"
           * keyCode 9            "tab"
           * keyCode 13           "enter"
           * keyCode 116          "F5"
           * keyCode 8            "backscape"
           * keyCode 37,38,39,40  Arrows
           * keyCode 10           (LF)
           */
          validate_int(event) {
            let isInt = false
            if (
              (event.charCode >= 48 && event.charCode <= 57) ||
              event.keyCode == 9 ||
              event.keyCode == 10 ||
              event.keyCode == 13 ||
              event.keyCode == 8 ||
              event.keyCode == 116 ||
              event.keyCode == 46 ||
              (event.keyCode <= 40 && event.keyCode >= 37)
            ) {
              isInt = true
            } else {
              isInt = false
            }
            return isInt
          }

          // Hubspot implementation used to provide error spans.
          // Now that we've switched to Marketo, we need to create those spans
          addErrorSpans() {
            var formInputElements = document.querySelectorAll(
              ".HubspotApiBlock__field-input"
            )
            formInputElements.forEach(inputElement => {
              var errorSpan = document.createElement("span")
              errorSpan.classList.add("error-message")
              if (inputElement.nextSibling) {
                inputElement.parentNode.insertBefore(
                  errorSpan,
                  inputElement.nextSibling
                )
              } else {
                inputElement.parentNode.appendChild(errorSpan)
              }
            })
          }

          validateOnSubmit() {
            let self = this

            this.form.addEventListener("submit", e => {
              e.preventDefault()
              self.fields.forEach(field => {
                self.validateFields(field)
              })
              self.validateFieldsSubmitOnly(self.fields)

              if (this.form.querySelector(".error")) {
                this.scrollToError(this.form.querySelector(".error"))
              }

              if (this.form.querySelectorAll(".error").length === 0) {
                this.handleSubmit()
              }
            })
          }

          scrollToError(field) {
            if (!field) {
              return
            }

            const header = document.querySelector("header")
            const headerHeight = header.getBoundingClientRect().height + 10
            const elPositionTop =
              field.getBoundingClientRect().top + window.pageYOffset

            if (window.pageYOffset + headerHeight > elPositionTop) {
              window.scrollTo(0, elPositionTop - headerHeight)
            }
          }

          async handleSubmit() {
            document.querySelector(
              ".HubspotApiBlock__processing"
            ).style.display = "inline-block"
            this.form.style.display = "none"
            const formData = new FormData(this.form)
            const submitData = {}
            for (let entry of formData.entries()) {
              submitData[entry[0]] = entry[1]
            }

            if (submitData["Annual_Volume_of_Checks__c"] !== "1") {
              await this.setUpABTesting()
            }


            // add abTestGroup to submitData
            if (this.abTestGroup !== null) {
              submitData["amplitudeExperimentBucket"] = this.abTestGroup
            }

            if (this.assistedAbTestGroup !== null) {
              submitData["amplitudeExperimentBucket"] = this.assistedAbTestGroup
            }

            // utm
            const utmFields = {
              utmsource: sessionStorage.getItem("utm_source"),
              utmmedium: sessionStorage.getItem("utm_medium"),
              utmcampaign: sessionStorage.getItem("utm_campaign"),
              utmid: sessionStorage.getItem("utm_id"),
              utmterm: sessionStorage.getItem("utm_term"),
              utmcontent: sessionStorage.getItem("utm_content"),
            }
            Object.keys(utmFields).forEach(key => {
              submitData[key] = utmFields[key]
            })

            // platformIds
            const platformIdFields = {
              msclkid: sessionStorage.getItem("msclkid"),
              fbclid: sessionStorage.getItem("fbclid"),
              li_fat_id: sessionStorage.getItem("li_fat_id"),
              GCLID__c: sessionStorage.getItem("gclid"),
            }
            Object.keys(platformIdFields).forEach(key => {
              submitData[key] = platformIdFields[key]
            })

            // landing page
            const landingPage = sessionStorage.getItem("landing_page")
            submitData["landingPageURL"] = landingPage

            // referrerUrl
            submitData["referrerURL"] = document.referrer

            this.marketoFormObject.setValues(submitData)
            this.marketoFormObject.submit()
          }

          shouldUseConditionalRedirect(submitData, conditionalRedirectOptions) {
            // parse options into a list where each string is formatted like "key:value"
            const optionKeyValueList = conditionalRedirectOptions.split(",")

            let useConditionalRedirect = false

            // check each conditional to see if we should use the conditional redirect url
            optionKeyValueList.forEach(keyValueString => {
              const parsedKeyValueStringArr = keyValueString.split(":")
              const conditionalField = parsedKeyValueStringArr[0]
              const conditionalValue = parsedKeyValueStringArr[1]

              const fieldValueToCheck = submitData[conditionalField]

              if (fieldValueToCheck === conditionalValue) {
                useConditionalRedirect = true
              }
            })

            return useConditionalRedirect
          }

          async redirectPostSubmit(submitData) {
            let redirectUrl = null

            if (Number(submitData["Annual_Volume_of_Checks__c"]) > 25 ) {
              await this.startAssistedExperiment(submitData)
            }

            if (!redirectUrl) {
              // set the redirectUrl to the conditional redirect url if conditions are met
              const conditionalRedirectUrl =
                this.form.dataset?.conditional_redirect_url
              const conditionalRedirectOptions =
                this.form.dataset?.conditional_redirect_options
              if (
                !!conditionalRedirectUrl?.length &&
                !!conditionalRedirectOptions?.length &&
                this.shouldUseConditionalRedirect(
                  submitData,
                  conditionalRedirectOptions
                )
              ) {
                redirectUrl = conditionalRedirectUrl
              }
            }

            if (!redirectUrl) {
              // set the redirectUrl to the conditional redirect url 2 if conditions 2 are met
              const conditionalRedirectUrl2 =
                this.form.dataset?.conditional_redirect_url_2
              const conditionalRedirectOptions2 =
                this.form.dataset?.conditional_redirect_options_2
              if (
                !!conditionalRedirectUrl2?.length &&
                !!conditionalRedirectOptions2?.length &&
                this.shouldUseConditionalRedirect(
                  submitData,
                  conditionalRedirectOptions2
                )
              ) {
                redirectUrl = conditionalRedirectUrl2
              }
            }

            // Amplitude A/B testing
            if (!redirectUrl && this.abTestGroup !== null) {
              if (!this.abTestGroup.includes("control")) {
                redirectUrl = `${process.env.GATSBY_AMPLITUDE_FOX_REDIRECT}`
              }
            }

            // if redirectUrl hasn't been set yet, just use the standard redirect url
            if (!redirectUrl) {
              const defaultRedirectUrl = this.form.dataset?.redirect_url
              redirectUrl = defaultRedirectUrl
            }

            // if no redirect url is defined, show thank you modal
            if (!redirectUrl && !!this.form.dataset.thankyouModal) {
              this.showModalThankYou()
            }

            // prevent redirect to a nonexistent page/404
            if (redirectUrl.slice(-1) !== "/") {
              redirectUrl = redirectUrl + "/"
            }

            // build params string for redirect url
            const relevantFields = ["phone", "company", "email"]
            let paramsString = Object.keys(submitData)
              .filter(key => relevantFields.includes(key.toLowerCase()))
              .map(
                key =>
                  encodeURIComponent(key.toLowerCase()) +
                  "=" +
                  encodeURIComponent(submitData[key])
              )
              .join("&")

            // build fullName field and tack to the end of the paramsString
            const fullName = encodeURIComponent(
              submitData["FirstName"] + " " + submitData["LastName"]
            )
            paramsString += "&fullName=" + fullName

            // build expected checks param and tack to the end of the paramsString
            const expected_checks_per_year =
              submitData["Annual_Volume_of_Checks__c"]
            if (!!expected_checks_per_year?.length) {
              const expected_checks_per_year_encoded = encodeURIComponent(
                expected_checks_per_year
              )
              paramsString +=
                "&expected_checks_per_year=" + expected_checks_per_year_encoded
            }

            // add isGoodhire url param
            paramsString += "&isGoodhire=true"

            // add ab testing group param
            if (this.abTestGroup !== null) {
              paramsString += "&amplitudeExperimentBucket=" + this.abTestGroup
            }

            if (this.assistedAbTestGroup !== null) {
              paramsString += "&amplitudeExperimentBucket=" + this.assistedAbTestGroup
            }

            redirectUrl = redirectUrl + "?" + paramsString

            await fetch('/.netlify/functions/prospectiveCustomer', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                ...submitData,
                redirectUrl,
              }),
            })

            navigate(redirectUrl)
          }

          showModalThankYou() {
            document.querySelector(
              ".HubspotApiBlock__processing"
            ).style.display = "none"
            document.querySelector(
              ".HubspotApiBlock__submission-error"
            ).style.display = "none"

            document.body.classList.add("modalThankYou-visible")

            let container = this.form.closest(".HubspotApiBlock").parentNode
            let thankyou = container.querySelector(
              ".HubSpotBlock-modal-thank-you"
            )

            if (thankyou) {
              let pageContent = document.querySelector(".entry-content")
              let displayThankYou = thankyou.cloneNode(true)

              displayThankYou.setAttribute(
                "id",
                "HubSpotBlock-modal-thank-you-display"
              )

              if (!pageContent)
                pageContent = document.querySelector(".Page-content")

              pageContent.insertBefore(
                displayThankYou,
                pageContent.hasChildNodes() ? pageContent.childNodes[0] : null
              )

              let closer = displayThankYou.querySelector(
                ".HubSpotBlock-modal-close"
              )

              displayThankYou.classList.add("is-visible")
              document.body.scrollTop = document.documentElement.scrollTop = 0

              if (closer) {
                closer.addEventListener("click", this.closeModal)
              }
            }
          }

          closeModal(event) {
            let closer = event.currentTarget
            let modal = closer.closest(
              ".HubSpotBlock-modal-thank-you.is-visible"
            )

            document.body.classList.remove("modalThankYou-visible")
            modal.parentNode.removeChild(modal)
          }

          validateOnEntry() {
            let self = this
            this.fields.forEach(field => {
              field.addEventListener("input", event => {
                self.validateFields(field)
              })
            })
          }

          INPUT_LIMIT = 100

          validateFields(field) {
            if (field.type === "hidden") {
              this.setStatus(field, null, "success")
              return
            }

            if (
              field.value.length > this.INPUT_LIMIT &&
              field.type === "text"
            ) {
              this.setStatus(field, this.errorMessages.tooLong, "error")
              return
            }

            // check for a valid email address
            if (field.type === "email") {
              const re =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if (re.test(field.value)) {
                this.setStatus(field, null, "success")
              } else if (field.value.length === 0) {
                this.setStatus(field, this.errorMessages.emailBlank, "error")
              } else {
                this.setStatus(field, this.errorMessages.emailInvalid, "error")
              }
              return
            }

            if (field.name.toLowerCase().includes("firstname")) {
              if (field.value.trim() === "") {
                this.setStatus(field, this.errorMessages.firstName, "error")
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            if (field.name.toLowerCase().includes("lastname")) {
              if (field.value.trim() === "") {
                this.setStatus(field, this.errorMessages.lastName, "error")
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            if (field.name.toLowerCase() === "company") {
              // Working regex that excludes special characters
              // const re = /^[A-Za-z0-9 ]+$/

              // Client provided regex
              const re = /^[a-zA-Z0-9!#$%&',()*+-\/=?^_\s`{|}~@:]*$/

              if (field.value.length === 0 || field.value.trim() === "") {
                this.setStatus(field, this.errorMessages.company, "error")
              } else if (
                this.form.dataset["company_validation"] !== "1" ||
                re.test(field.value)
              ) {
                this.setStatus(field, null, "success")
              } else {
                this.setStatus(
                  field,
                  this.errorMessages.companyInvalid,
                  "error"
                )
              }

              // Leaving in case we need to revert back
              // if (field.value.trim() === "") {
              //   this.setStatus(field, this.errorMessages.company, "error")
              // } else {
              //   this.setStatus(field, null, "success")
              // }
              return
            }

            if (field.name.toLowerCase() === "time_to_first_check") {
              if (field.value.trim() === "") {
                this.setStatus(field, this.errorMessages.generic, "error")
              } else {
                this.setStatus(field, null, "success")
              }
            }

            if (field.name.toLowerCase() === "company_phone_number") {
              if (field.value.trim() === "") {
                this.setStatus(field, this.errorMessages.firstName, "error")
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            if (field.name.toLowerCase().includes("phone")) {
              const numbersOnly = field.value.replace(/\D/g, "")
              if (numbersOnly.length === 0) {
                this.setStatus(field, this.errorMessages.phoneBlank, "error")
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            if (field.name.toLowerCase().includes("annual")) {
              if (field.value.trim() === "") {
                this.setStatus(
                  field,
                  this.errorMessages.expectedChecks,
                  "error"
                )
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            if (field.name.toLowerCase().includes("exact")) {
              const range = field.parentElement.dataset.range
              const possibleValues = range
                ? range.split(":").slice(0, 2)
                : [1, 11]
              const [min, max] = possibleValues

              if (field.value.trim() === "") {
                this.setStatus(
                  field,
                  this.errorMessages.exactBlank(min, max),
                  "error"
                )
              } else if (+field.value < +min || +field.value > +max) {
                if (+min === "501") {
                  if (+field.value > +max) {
                    this.setStatus(field, null, "success")
                  } else if (+field.value < +min) {
                    this.setStatus(
                      field,
                      this.errorMessages.exactBelow(min),
                      "error"
                    )
                  }
                  return
                }
                this.setStatus(
                  field,
                  this.errorMessages.exactBlank(min, max),
                  "error"
                )
              } else {
                this.setStatus(field, null, "success")
              }
              return
            }

            // Default/fallback field validation
            if (field.value.trim() === "") {
              this.setStatus(field, this.errorMessages.generic, "error")
            } else {
              this.setStatus(field, null, "success")
            }
          }

          validateFieldsSubmitOnly(fields) {
            const emailField = fields.find(field => field.type === "email")
            const expectedChecksField = fields.find(field =>
              field.name.toLowerCase().includes("annual_volume_of_checks")
            )
            const shouldValidateBusinessEmail =
              this.form.dataset?.business_email_validation === "1"
            if (!!emailField && !!expectedChecksField) {
              if (
                shouldValidateBusinessEmail &&
                (expectedChecksField.value === "750" ||
                  expectedChecksField.value === "1001") &&
                NonBusinessEmailDomains.some(nonBusinessDomain =>
                  emailField.value.endsWith(nonBusinessDomain)
                )
              ) {
                this.setStatus(
                  emailField,
                  this.errorMessages.emailBusinessInvalid,
                  "error"
                )
                return
              }
            }
          }

          setStatus(field, message, status) {
            const errorMessage =
              field.parentElement.querySelector(".error-message")

            if (status === "success") {
              if (errorMessage) {
                errorMessage.innerText = ""
              }

              field.classList.remove("error")
            }

            if (status === "error") {
              field.parentElement.querySelector(".error-message").innerText =
                message
              field.classList.add("error")
            }
          }

          errorMessages = {
            firstName: "Please enter your first name",
            lastName: "Please enter your last name",
            company: "Please enter your company name",
            companyInvalid:
              "There is an invalid character in your company name",
            phoneBlank: "Please enter your phone number",
            phoneInvalid: "Please enter a valid phone number",
            emailBlank: "Please enter your work email address",
            emailInvalid: "Email must be formatted correctly",
            emailBusinessInvalid: "Please enter a business email address",
            expectedChecks:
              "Please tell us how many checks you expect to run per year",
            belowThreshold: "Please enter a number between 11 and 25",
            generic: "This field is required",
            tooLong: "Maximum characters is 100",
            exactBlank: (min, max) =>
              `Please enter a number between ${min} and ${max}`,
            exactBelow: min => `Please enter a number that is ${min} or more`,
            exactAbove: max => `Please enter a number that is ${max} or less`,
          }
        }

        // Initialize Form
        const form = document.querySelector(".hs-form")
        if (form) {
          const fields = form.querySelectorAll(".HubspotApiBlock__field-input")
          const marketoForm = new MarketoFormController(form, [...fields])
          await marketoForm.initialize()
        }
      })()
  }
}
