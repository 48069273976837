export const NonBusinessEmailDomains = [
  "4feldco.com",
  "14icloud.com",
  "16gmail.com",
  "266gmail.com",
  "856gmail.com",
  "778866gmail.com",
  "ab1st.info",
  "aftia.com",
  "aim.com",
  "alice.it",
  "aliceadsl.fr",
  "alncorp.com",
  "americanhotel.com",
  "americares.org",
  "anexinet.com",
  "aol.com",
  "appian.com",
  "arcca.com",
  "arcesium.com",
  "arcor.de",
  "asplundh.com",
  "att.net",
  "autonomoussolutions.com",
  "avonbeautyboss.com",
  "axelon.com",
  "b2bstaffingservices.com",
  "bacisity.com",
  "baeauto.com",
  "bayberryclean.com",
  "bellsouth.net",
  "bigpond.com",
  "bjc.org",
  "blackwellhr.com",
  "boeing.com",
  "bol.com.br",
  "bolanainc.com",
  "bottleneckmgmt.com",
  "brianbbunncpa.com",
  "brinkleypharmacy.ca",
  "bti.org",
  "burgerandlobster.com",
  "business.com",
  "carringtonacademy.com",
  "cavenderauto.com",
  "cbrands.com",
  "centurytel.net",
  "chambers.bank",
  "charter.net",
  "choicehotels.com",
  "clifbar.com",
  "cnhind.com",
  "cns-inc.com",
  "codeninjas.com",
  "comcast.net",
  "contelec.com",
  "couryhospitality.com",
  "cox.net",
  "cpjfkairport.com",
  "cssinc.co",
  "customcleanatlantic.ca",
  "dctdcs.org",
  "destaco.com",
  "det-tronics.com",
  "discountsmokestores.com",
  "dmimail.com",
  "dominiontec.com",
  "dot.com",
  "dukemfg.com",
  "earthlink.net",
  "eastsidegc.com",
  "ehammersmith.com",
  "elfcosmetics.com",
  "email.c",
  "email.com",
  "enersys.com",
  "estesmcclure.com",
  "extremeonpromotions.com",
  "fabco-air.com",
  "finallyhelp.com",
  "firstfreerockford.org",
  "firstronic.com",
  "fivesgroup.com",
  "flexfab.com",
  "fmsksksk.com",
  "free.fr",
  "freenet.de",
  "frontiercoop.com",
  "frontiernet.net",
  "fuelrecruiters.com",
  "gaia-techs.com",
  "galco.com",
  "gamestop.com",
  "gamil.com",
  "gcpaint.com",
  "getcrs.com",
  "gimal.com",
  "glenraven.com",
  "gma.com",
  "gmai.com",
  "gmail.co",
  "gmail.com",
  "gmail.coma",
  "gmail.comm",
  "gmail.con",
  "gmail.coom",
  "gmal.com",
  "gmaol.com",
  "gmell.com",
  "gmil.com",
  "gmsil.com",
  "gmx.de",
  "gmx.net",
  "gnail.com",
  "gogirlride.com",
  "googlemail.com",
  "gortons.com",
  "greeneresources.com",
  "griffithfoods.com",
  "happylifemaids.net",
  "harrisconsultingindy.com",
  "hcsedu.org",
  "hiltonwaikikihotel.com",
  "hmail.com",
  "holtrenfrew.com",
  "homail.com",
  "home.nl",
  "homeaidhealthcare.com",
  "homehappyclean.com",
  "homehelpershomecare.com",
  "hotmail.ca",
  "hotmail.co.uk",
  "hotmail.com",
  "hotmail.con",
  "hotmail.de",
  "hotmail.es",
  "hotmail.fr",
  "hotmail.it",
  "hsd.ca",
  "hufna.com",
  "hwashin-usa.com",
  "icloud.com",
  "ig.com.br",
  "ikosconsulting.com",
  "inmar.com",
  "intermedia.net",
  "jasint.com",
  "jeeptruck.org",
  "jennifermurrayconsulting.com",
  "jmail.com",
  "johnvarvatos.com",
  "kimball.com",
  "ksiedge.com",
  "kw.com",
  "kyndlecapital.com",
  "laposte.net",
  "latestedge.com",
  "legendaryevents.com",
  "libero.it",
  "live.ca",
  "live.co.uk",
  "live.com",
  "live.fr",
  "live.it",
  "live.nl",
  "lstractorusa.com",
  "m-v-t.com",
  "mac.com",
  "macsearch.ca",
  "mail.com",
  "mail.fresnostate.edu",
  "mail.ru",
  "mainelytubs.com",
  "marykay.com",
  "mctf.info",
  "me.com",
  "merritthawkins.com",
  "mgoil.com",
  "mhsstaff.info",
  "middletonmeyers.com",
  "midlandhorseshoe.com",
  "mir-belting.com",
  "movadogroup.com",
  "msn.com",
  "mtl.sysco.ca",
  "mybobs.com",
  "n3results.com",
  "nacdonline.org",
  "nativo.com",
  "ncbpinc.com",
  "nescoresource.com",
  "neuf.fr",
  "nexthink.com",
  "ntlworld.com",
  "oasissystems.com",
  "oemfab.com",
  "omega-usa.com",
  "omnicell.com",
  "ontheborder.com",
  "optimo-it.com",
  "optonline.net",
  "orange.fr",
  "outlook.com",
  "owwc.com",
  "pacificitopsignature.com",
  "partners.org",
  "pccsny.org",
  "people20.com",
  "pfdinc.net",
  "phillipspet.com",
  "planet.nl",
  "pm.me",
  "projekt202.com",
  "protonmail.com",
  "qq.com",
  "raisingcanes.com",
  "rambler.ru",
  "rediffmail.com",
  "redlineathletics.com",
  "regionalgroup.com",
  "reqroute.com",
  "rise.org",
  "rndc-usa.com",
  "roadrunner.com",
  "rocketmail.com",
  "rothstaffing.com",
  "rscs.com",
  "sac.scsmoving.com",
  "sanctuaryhomecareagency.com",
  "sbcglobal.net",
  "sewanhakaschools.org",
  "sfdctest.com",
  "sfr.fr",
  "shaw.ca",
  "sixforces.com",
  "sky.com",
  "skyone.org",
  "sleepnumber.com",
  "smartsittersclub.com",
  "snet.net",
  "splendoraisd.org",
  "stamped.io",
  "standardtextile.com",
  "standav.com",
  "suddenlink.net",
  "supermicro.com",
  "t-online.de",
  "talentbridge.com",
  "teammurph.net",
  "telenet.be",
  "terra.com.br",
  "test.com",
  "thewoodsgroup.co",
  "thrivepeersupport.com",
  "tidri.com",
  "tin.it",
  "tiscali.co.uk",
  "tiscali.it",
  "toririchard.com",
  "totalcleaning.com",
  "uci.edu",
  "uol.com.br",
  "usa.com",
  "usaconstrutbuild.com",
  "uww.edu",
  "valleyhomehealthcareagency.com",
  "verizon.net",
  "virgilio.it",
  "voila.fr",
  "wanadoo.fr",
  "watlow.com",
  "web.de",
  "westinsandiego.com",
  "whg.com",
  "winconn.com",
  "xtremecleans.com",
  "yahoo.ca",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.co.jp",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.com.ar",
  "yahoo.com.au",
  "yahoo.com.br",
  "yahoo.com.mx",
  "yahoo.de",
  "yahoo.es",
  "yahoo.fr",
  "yahoo.in",
  "yahoo.it",
  "yandex.ru",
  "ymail.com",
  "zensar.com",
  "zizzahighwayservices.com",
]
